import React from 'react'
import { Box, Text, Flex, Heading } from 'rebass/styled-components'
import { Container } from './UI'
import Animation from './Animation'
import Fade from 'react-reveal/Fade'
import { Grid, GridChild } from './GridLayout'
import styled, { ThemeProvider, withTheme } from 'styled-components'
import theme, {
  orange as orangeTheme,
  dark as darkTheme,
  slate as slateTheme
} from '../theme'
import Carousel from './Carousel'
import { H4 } from './Type'

const QuoteWrapper = styled.div`
  position: absolute;
  bottom: -15px;
  left: 0;
  height: 130px;
  width: 110px;
  opacity: 0.25;
  svg {
    g {
      path {
        fill: #0f0f1c !important;
      }
    }
  }
`

const BioText = props => (
  <Text
    sx={{
      opacity: 0.4,
      letterSpacing: '3px',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '17px',
      textTransform: 'uppercase'
    }}
    {...props}
  />
)

const Testimonials = {
  Birthdate: props => (
    <Testimonial {...props}>
      <Text variant='p'>
        They combine a strong business/entrepreneurial sense with deep
        analytical, design, and development know-how in a way that few others
        can.
      </Text>
      <Text variant='p'>
        They understand the trade offs between speed and quality that
        enterpreneurs intuitively get - delivering a very high-quality finished
        product, but also focus on shipping quickly.
      </Text>
      <BioText mt={2}>
        Ajay Mehta
        <br />
        CEO,
        <br />
        Birthdate Candles
      </BioText>
    </Testimonial>
  ),
  Jambys: props => (
    <Testimonial {...props}>
      <Text variant='p'>
        From the beginning, Forge took time to understand our goals, clarify our
        thinking, and collaborate super painlessly. Forge felt more like an
        in-house team than an agency — I always felt like we were on the same
        page. They pointed us in the right direction for our business, where
        plenty of other agencies will point you in the right direction for their
        business. Forge took time to understand our vision and I leaned on their
        previous experience running startups when trying to make decisions.
        Hiring them was the best decision we’ve made.
      </Text>
      <BioText mt={2}>
        Andrew Goble
        <br />
        CEO,
        <br />
        Jambys
      </BioText>
    </Testimonial>
  ),
  Mammals: props => (
    <Testimonial {...props}>
      <Text variant='p'>
        Forge combines focus and out of the box creativity to deliver incredible
        consumer experiences. They are an analytical and open-minded team, one
        that will contribute their own ideas as well as help you assess yours.
        They were our brand partner as we launched, and continue to deliver
        insights on product and positioning decisions.
      </Text>
      <BioText mt={2}>
        Joe Moriarty
        <br />
        CEO,
        <br />
        Modern Mammals
      </BioText>
    </Testimonial>
  )
}

const Testimonial = props => {
  return (
    <Flex
      width={['auto', 'auto', 1 / 3]}
      flexShrink={0}
      flexDirection='column'
      height='100%'
      justifyContent={['flex-start', 'flex-start', 'center']}
      pl={['1.5rem', 2, '0.5rem']}
      pr={[0, 0, '0.5rem']}
    >
      <Box
        ml='24px'
        mt='24px'
        width='100%'
        sx={{
          overflow: 'hidden',
          position: 'relative',
          height: '30px',
          zIndex: 1
        }}
      >
        <QuoteWrapper>
          <Animation
            delay={props.delay}
            name='Quote'
            aspectRatio='xMinYMin meet'
          />
        </QuoteWrapper>
      </Box>
      {/* <Fade delay={props.delay || 0} bottom> */}
      <Box
        sx={{
          borderRadius: '4px'
        }}
        bg={['white', 'white', 'beige']}
        px={'24px'}
        pb='24px'
        pt={'54px'}
        mt={'-54px'}
      >
        <Box py={1}>{props.children}</Box>
      </Box>
      {/* </Fade> */}
    </Flex>
  )
}

const TestimonialsSection = props => {
  return (
    <Flex
      bg='peach'
      minHeight='93vh'
      justifyContent='center'
      flexDirection='column'
    >
      <Grid
        bg='peach'
        minHeight='auto'
        px={['1.5rem', 4, 3, 9]}
        sx={{
          display: 'grid',
          gridAutoColumns: ['49%'],
          gridTemplateRows: 'auto',
          gridGap: '2%',
          gridTemplateColumns: ['none', 'repeat(3,1fr)']
        }}
      >
        <GridChild
          colStart={[1, 1]}
          colEnd={[3, 4]}
          textAlign={['left', 'center']}
        >
          <H4 mb={0}>What do our clients say about us?</H4>
        </GridChild>
        <GridChild colStart={1} colEnd={[3, 4]}>
          <Box
            mx={['-1.5rem', -4, -3, -9]}
            sx={{
              display: ['block', 'block', 'none']
            }}
          >
            <Carousel
              showNavButtons
              navProps={{
                px: ['1.5rem', '2rem']
              }}
              partialVisible={true}
              responsive={{
                small: {
                  partialVisibilityGutter: 48,
                  breakpoint: { max: 450, min: 0 },
                  items: 1,
                  slidesToSlide: 1
                },

                medium: {
                  partialVisibilityGutter: 100,
                  breakpoint: { max: 640, min: 450 },
                  items: 1,
                  slidesToSlide: 1
                },
                mediumUp: {
                  partialVisibilityGutter: 180,
                  breakpoint: { max: 10000, min: 640 },
                  items: 1,
                  slidesToSlide: 1
                }
              }}
              arrows={false}
              showDots={false}
              infinite={true}
            >
              <Testimonials.Birthdate />
              <Testimonials.Jambys delay={300} />
              <Testimonials.Mammals delay={600} />
            </Carousel>
          </Box>
          <Box
            mx={-1}
            sx={{
              display: ['none', 'none', 'flex'],
              alignItems: 'center',
              flexWrap: 'nowrap'
            }}
          >
            <Testimonials.Birthdate delay={300} />
            <Testimonials.Jambys />
            <Testimonials.Mammals delay={600} />
          </Box>
        </GridChild>
      </Grid>
    </Flex>
  )
}

export default TestimonialsSection
