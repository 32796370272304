const COLORS = {
  BLACK: '#0F0F1C',
  ORANGE: '#F24E27',
  SLATE: '#4C4759',
  MIDNIGHT: '#362632',
  YELLOWORANGE: '#F47152',
  MUSTARD: '#F68E58',
  DEEPBROWN: '#380C06',
  MAROON: '#933021',
  MAUVE: '#916A6B',
  BEIGE: '#FFF4E9',
  LIGHTPINK: '#FDDFD8',
  MOCHA: '#362632'
}

export default COLORS
