import React from 'react'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'
import styled from 'styled-components'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'
import CaretDown from '../components/icons/Caret Down.svg'
import CaretUp from '../components/icons/Caret Up.svg'
import CapabilityBranding from '../components/icons/CapabilityBranding.svg'
import CapabilityStrategy from '../components/icons/CapabilityStrategy.svg'
import CapabilityUxDesign from '../components/icons/CapabilityUxDesign.svg'
import CapabilityWebDev from '../components/icons/CapabilityWebDev.svg'
import CapabilityCopywriting from '../components/icons/CapabilityCopywriting.svg'
import CapabilityArtDirection from '../components/icons/CapabilityArtDirection.svg'
import CapabilityCreative from '../components/icons/CapabilityCreative.svg'

const StyledAccordionItem = styled(AccordionItem)`
  .accordion__panel {
    transition: max-height 250ms ease-in-out;
    overflow: hidden;
  }
  .accordion__panel[hidden] {
    display: block;
    max-height: 0;
  }
  .accordion__panel:not([hidden]) {
    max-height: 100vh;
  }
`

const Capability = ({ name, text, Icon }) => (
  <StyledAccordionItem>
    <AccordionItemState>
      {({ expanded }) => (
        <Box
          p={1}
          bg={expanded ? 'brown' : ''}
          sx={{
            borderRadius: '.5rem',
            transition: 'background-color 100ms ease-in-out'
          }}
        >
          <AccordionItemHeading>
            <AccordionItemButton style={{ outline: 'none', cursor: 'pointer' }}>
              <Flex alignItems='center'>
                <Icon height={2} width={2} mr={1} />
                <Text fontSize={1} flexGrow={1}>
                  {name}
                </Text>
                {expanded ? <CaretUp /> : <CaretDown />}
              </Flex>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Text opacity={0.8} mt={'.5rem'} ml={3}>
              {text}
            </Text>
          </AccordionItemPanel>
        </Box>
      )}
    </AccordionItemState>
  </StyledAccordionItem>
)

const CapabilitiesAccordion = props => (
  <Accordion allowMultipleExpanded={false} allowZeroExpanded>
    <Capability
      name='Branding'
      text='We’re capable of bringing a brand to life, or polishing something that already works. Our work here includes naming, logo design, color selection, typography, and more. '
      Icon={CapabilityBranding}
    />
    {/* <Capability
      name='Strategy'
      text='Where does your product sit in the market? How can we make it stand out? We do thoughtful diligence on the competitive landscape and your consumer to develop a memorable brand.'
      Icon={CapabilityStrategy}
    /> */}
    <Capability
      name='UX Design'
      text='We’re user-centered designers by trade. We deploy best practices to design elegant experiences that convert. '
      Icon={CapabilityUxDesign}
    />
    <Capability
      name='Web Development'
      text='Our speciality is front-end development across web and mobile. We use the latest frameworks and libraries to make fast, responsive sites and apps.'
      Icon={CapabilityWebDev}
    />
    <Capability
      name='Copywriting'
      text='We embody your brand’s voice and craft succinct, effective copy.'
      Icon={CapabilityCopywriting}
    />
    <Capability
      name='Art Direction'
      text='Product photography, scenes, shoots and locations — we’ll guide and manage the whole shabang.'
      Icon={CapabilityArtDirection}
    />
    {/* <Capability
      name='Ongoing Creative'
      text='We can generate any creative  your team needs once you launch, including, email assets, social media creative, and advertising assets. '
      Icon={CapabilityCreative}
    /> */}
  </Accordion>
)

export default CapabilitiesAccordion
