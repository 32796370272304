import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Underline } from '../components/Type'
import Fade from 'react-reveal/Fade'
import Animation from '../components/Animation'
import { OnlyMobile, HideMobile, OffsetWrapper } from '../components/GridLayout'
import COLORS from '../components/colors'
import Layout from '../components/layout'
import CapabilitiesAccordion from '../components/CapabilitiesAccordion.js'
import { ThemeProvider, withTheme } from 'styled-components'
import { Box, Button, Heading, Text } from 'rebass/styled-components'
import theme, {
  orange as orangeTheme,
  dark as darkTheme,
  slate as slateTheme
} from '../theme'
import ArrowRight from '../components/icons/Arrow Right.svg'
import FeaturedProjectsSection from '../components/FeaturedProjectsSection'
import TestimonialsSection from '../components/TestimonialsSection'
import { Grid, GridChild } from '../components/GridLayout'
import Link from '../components/Link'
import { H4 } from '../components/Type'

class HomePage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main>
          <ThemeProvider theme={darkTheme}>
            <Grid bg='bgColor'>
              <GridChild
                colStart={1}
                colEnd={[3, 5]}
                rowStart={1}
                rowEnd={[4, 5]}
              >
                <OffsetWrapper offset={3}>
                  <Box
                    sx={{
                      overflow: 'visible',
                      position: 'relative',
                      height: '100%',
                      width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                      }}
                    >
                      <Animation delay={1000} name='WhatIsForge' />
                    </Box>
                  </Box>
                </OffsetWrapper>
              </GridChild>

              <GridChild
                colStart={[1, 4]}
                colEnd={[3, 7, 7, 6]}
                rowStart={[5, 3]}
                rowEnd={[7, 5]}
              >
                <Fade delay={1000} bottom ssrReveal>
                  <div>
                    <H4>What is Forge?</H4>
                    <Text>
                      Forge is a tight-knit team of designers, builders, and
                      creators. We partner with growing consumer businesses to
                      craft their brand, design, and user experience.
                    </Text>
                  </div>
                </Fade>
              </GridChild>
            </Grid>
          </ThemeProvider>

          <FeaturedProjectsSection />

          <ThemeProvider theme={slateTheme}>
            <Grid
              bg='bgColor'
              style={{ position: 'relative', overflow: 'visible', zIndex: 1 }}
              pb={[3, 1, 1]}
              sx={{ gridTemplateRows: ['25vh auto', 'repeat(4,1fr)'] }}
            >
              <GridChild
                colStart={[1, 4]}
                colEnd={[3, 6]}
                rowStart={[1, 1]}
                rowEnd={[2, 5]}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    width: ['100%', '50%'] //'40%']
                  }}
                >
                  <Animation name='Venn' delay={1000} />
                </Box>
              </GridChild>
              <GridChild
                colStart={[1, 1]}
                colEnd={[3, 4]}
                rowStart={[2, 1]}
                rowEnd={[3, 5]}
              >
                <Fade delay={200} bottom>
                  <Box mt={9}>
                    <H4>What's our "Sweet Spot"?</H4>
                    <Text>
                      We believe the best work results from unified thinking and
                      effort, which is why we span the following disciplines:
                    </Text>
                    <Box mt={1}>
                      <CapabilitiesAccordion />
                    </Box>
                  </Box>
                </Fade>
              </GridChild>
            </Grid>
            <Grid
              bg={COLORS.MIDNIGHT}
              sx={{ position: 'relative' }}
              pt={[20, 0]}
              mt={-1}
            >
              <GridChild
                colStart={[1, 4]}
                colEnd={[3, 7]}
                rowStart={[4, 2]}
                rowEnd={[7, 5]}
              >
                <Fade delay={200} bottom>
                  <div>
                    <H4>Are we the right partner for you?</H4>
                    <Text variant='p'>
                      You're looking to launch or grow a consumer business.
                      Often times your product is in market and you now need a
                      partner to take you to the next level.
                    </Text>
                    <Text variant='p'>
                      We believe in doing work that produces results, and
                      typically structure our relationships that way — whether
                      revenue share, performance-based payouts, or equity.
                    </Text>
                  </div>
                </Fade>
              </GridChild>
              <GridChild
                colStart={[1, 4]}
                colEnd={[3, 6]}
                rowStart={[1, 1]}
                rowEnd={[2, 5]}
              >
                <Box
                  sx={{ position: 'absolute', left: 0, width: ['100%', '40%'] }}
                >
                  <Animation name='Partners' delay={750} />
                </Box>
              </GridChild>
            </Grid>
          </ThemeProvider>

          <TestimonialsSection />

          <ThemeProvider theme={darkTheme}>
            <Grid bg='bgColor'>
              <GridChild
                colStart={[1, 3]}
                colEnd={[3, 7]}
                rowStart={1}
                rowEnd={[4, 5]}
                ml={[0, 4, 4, 0]}
                mr={[0, -4, -7, 0]}
              >
                <OffsetWrapper offset={3}>
                  <Animation delay={250} name='People' />
                </OffsetWrapper>
              </GridChild>

              <GridChild
                colStart={[1, 1]}
                colEnd={[3, 4, 4, 3]}
                rowStart={[4, 2]}
                rowEnd={[7, 5]}
              >
                <Fade bottom>
                  <Box mt={[2, 0]}>
                    <H4>Who are we?</H4>
                    <Text>
                      We’re Aneesh, Ashwinn and Phil. Together we’ve built
                      consumer apps that have had hundreds of thousands of
                      users, and helped launch brands that have done millions in
                      sales. We’ve run and operated our own businesses, so we
                      know how to think strategically about your product and
                      users.
                    </Text>
                  </Box>
                </Fade>
              </GridChild>
            </Grid>
          </ThemeProvider>

          <Grid bg='beige'>
            <GridChild
              colStart={[1, 1]}
              colEnd={[3, 5]}
              rowStart={[4, 1]}
              rowEnd={[7, 5]}
              ml={[0, -4, -4, 0]}
            >
              <OffsetWrapper offset={6}>
                <Animation name='Cabin' loop />
              </OffsetWrapper>
            </GridChild>
            <GridChild
              colStart={[1, 4]}
              colEnd={[3, 7]}
              rowStart={[1, 2]}
              rowEnd={[5, 6]}
            >
              <Fade delay={200} bottom>
                <div>
                  <H4>What is a co-op?</H4>
                  <Text variant='p'>
                    As a co-op, we operate as a democratic workplace and ascribe
                    to the seven{' '}
                    <Link href='https://www.ica.coop/en/cooperatives/cooperative-identity#cooperative-principles'>
                      cooperative principles
                    </Link>
                    .
                  </Text>
                  <Text variant='p'>
                    Additionally, we donate labor to cooperatives and causes we
                    feel passionate about.
                  </Text>
                </div>
              </Fade>
            </GridChild>
          </Grid>
        </main>
      </Layout>
    )
  }
}

HomePage.propTypes = {
  route: PropTypes.object
}

export default withTheme(HomePage)
